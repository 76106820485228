import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { motion } from 'framer-motion';
import { HomeOutlined, InfoCircleOutlined, ProjectOutlined, ContactsOutlined, SolutionOutlined } from '@ant-design/icons';
import './NavigationBar.css';

const menuItems = [
  { key: 'home', label: 'Home', icon: <HomeOutlined />, href: '#home' },
  { key: 'about', label: 'About', icon: <InfoCircleOutlined />, href: '#about' },
  { key: 'project', label: 'Project', icon: <ProjectOutlined />, href: '#project' },
  { key: 'experience', label: 'Experience', icon: <SolutionOutlined />, href: '#experience' },
  { key: 'contact', label: 'Contact', icon: <ContactsOutlined />, href: '#contact' },
];

const NavigationBar: React.FC = () => {
  const [current, setCurrent] = useState<string>('home');

  const handleClick = (e: { key: string }) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    const sectionIds = menuItems.map(item => item.key);
    const sections = sectionIds.map(id => document.getElementById(id));

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this value as needed
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCurrent(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach(section => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: 'spring', stiffness: 80 }}
      className="navbar"
    >
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        className="menu"
      >
        {menuItems.map(item => (
          <Menu.Item key={item.key} icon={item.icon}>
            <a href={item.href} className="nav-link">{item.label}</a>
          </Menu.Item>
        ))}
      </Menu>
    </motion.div>
  );
};

export default NavigationBar;
