import React from 'react';
import { Layout, Typography } from 'antd';
import './FooterBar.css';

const { Footer } = Layout;
const { Text } = Typography;

const FooterBar: React.FC = () => {
  return (
    <Footer className="footer-bar">
      <Text className="footer-text">
        © {new Date().getFullYear()} Prayash Shrestha. All Rights Reserved.
      </Text>
    </Footer>
  );
};

export default FooterBar;
