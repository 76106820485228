import React from 'react';
import { Timeline, Typography } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import './Experience.css';

const { Title, Text } = Typography;

const experiences = [
  {
    company: "Grepsr Pvt. Ltd.",
    role: "Delivery Engineer",
    duration: "May 2023 - Sep 2023",
    overview: "Optimized web crawlers for data scraping, transitioned the stack to TypeScript and Go, and implemented CI/CD pipelines.",
    techStack: ["JavaScript", "TypeScript", "Go", "Kubernetes", "Selenium", "Puppeteer"],
  },
  {
    company: "Wiseyak Inc.",
    role: "Software Engineer",
    duration: "May 2022 - May 2023",
    overview: "Architected microservices infrastructure and designed Docker-based applications, improving deployment consistency.",
    techStack: ["Scala", "Java", "TypeScript", "React", "Docker", "Kubernetes"],
  },
  {
    company: "Prismasofts",
    role: "Full Stack Web Developer",
    duration: "Jun 2021 - Apr 2022",
    overview: "Enhanced application performance by optimizing backend logic and implementing lazy loading in React.",
    techStack: ["JavaScript", "TypeScript", "React", "Redux", "MongoDB", "PostgreSQL"],
  },
  // Add more experiences as needed
];

const Experience: React.FC = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const timelineElement = document.getElementById('timeline');
      const bounding = timelineElement?.getBoundingClientRect();
      if (bounding && bounding.top < window.innerHeight && bounding.bottom > 0) {
        controls.start('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  return (
    <div className="section-experience" id='experience'>
      <Title level={2} className="experience-title">My Experience</Title>
      <div id="timeline">
        <Timeline className="experience-timeline">
          {experiences.map((exp, index) => (
            <Timeline.Item key={index}>
              <motion.div
                className="timeline-item"
                initial="hidden"
                animate={controls}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.5, delay: index * 0.3 },
                  },
                }}
              >
                <Title level={4} className="company-title">{exp.company}</Title>
                <Text className="role-duration">{exp.role} - {exp.duration}</Text>
                <Text className="experience-overview">{exp.overview}</Text>
                <div className="tech-stack">
                  <Title level={5} className="tech-stack-title">Tech Stack Used</Title>
                  <div className="tech-stack-grid">
                    {exp.techStack.map((tech, techIndex) => (
                      <div key={techIndex} className="tech-item">{tech}</div>
                    ))}
                  </div>
                </div>
              </motion.div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default Experience;
