import React from 'react';
import { Typography } from 'antd';
import { motion } from 'framer-motion';
import './Banner.css';
import image from '../../assets/prayash.jpg';

const { Title, Text } = Typography;

const Banner: React.FC = () => {
  return (
    <div className="banner-container" id='home'>
      <motion.div
        className="banner-left"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 80 }}
      >
        <Title level={1} className="banner-title">
          Hi, I'm <span className="highlight">Prayash Shrestha</span>
        </Title>
        <motion.div
          className="banner-subtitle"
          initial={{ x: -20 }}
          animate={{ x: 20 }}
          transition={{
            repeat: Infinity,
            repeatType: 'mirror',
            duration: 2,
          }}
        >
          <Title level={3} className="banner-subtitle-text">
            Full-Stack Software Engineer
          </Title>
        </motion.div>
        <div className="tech-stack">
          <Text>⚡ Expertise in React, Node.js, and TypeScript</Text>
          <Text>⚡ Experienced in building scalable web applications</Text>
          <Text>⚡ Passionate about clean code and best practices</Text>
        </div>
      </motion.div>
      <motion.div
        className="banner-right"
        initial={{ y: 0 }}
        animate={{ y: [0, -20, 0] }} /* Smooth up-and-down animation */
        transition={{
          duration: 4,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        <div className="image-container">
          <img
            src={image}
            alt="Prayash Shrestha"
            className="banner-image"
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Banner;
