import React from 'react';
import { Typography } from 'antd';
import { motion } from 'framer-motion';
import './About.css';

const { Title, Text } = Typography;

const techIcons = [
  { src: "https://img.icons8.com/color/48/000000/javascript.png", alt: "JavaScript" },
  { src: "https://img.icons8.com/color/48/000000/typescript.png", alt: "TypeScript" },
  { src: "https://img.icons8.com/color/48/000000/react-native.png", alt: "React" },
  { src: "https://img.icons8.com/color/48/000000/nodejs.png", alt: "Node.js" },
  { src: "https://img.icons8.com/color/48/000000/java-coffee-cup-logo.png", alt: "Java" },
  { src: "https://img.icons8.com/color/48/000000/docker.png", alt: "Docker" },
  { src: "https://img.icons8.com/color/48/000000/kubernetes.png", alt: "Kubernetes" },
];

const About: React.FC = () => {
  return (
    <div className="section-about" id='about'>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <Title level={2} className="about-title">About Me</Title>
        <Text className="about-text">
          I am a skilled Full Stack Software Engineer with nearly 3 years of experience in enterprise application development, particularly in the healthcare sector. I excel in planning, coding, and testing within the SDLC and am an expert in problem identification, code improvement, and application performance enhancement.
        </Text>
        <Text className="about-text">
          My expertise spans across various technologies including Java, JavaScript, React, Spring, Microservices, Cloud, and RESTful services. I am dedicated to creating high-performance production systems that meet diverse client needs, with a strong focus on optimizing technology for software development growth.
        </Text>
      </motion.div>

      <motion.div className="tech-stack-container">
        <motion.div
          className="tech-stack-row"
          initial={{ x: 0 }}
          animate={{ x: '-100%' }}
          transition={{
            duration: 20,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          {techIcons.map((icon, index) => (
            <motion.div key={index} className="tech-icon-wrapper">
              <img src={icon.src} alt={icon.alt} className="tech-icon" />
            </motion.div>
          ))}
          {/* Duplicate the icons to create a continuous loop */}
          {techIcons.map((icon, index) => (
            <motion.div key={index + techIcons.length} className="tech-icon-wrapper">
              <img src={icon.src} alt={icon.alt} className="tech-icon" />
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default About;
