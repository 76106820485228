import React, { useState } from 'react';
import { Card, Button, Modal, Typography } from 'antd';
import './Project.css';

const { Title, Text, Link } = Typography;

const projects = [
  {
    name: "News Portal",
    description: "University Project For Web Programming Course",
    details: "This is a news portal project using nextjs, express, typescript and postgress. We have implemented role based authorization and latest features of next js.",
    techStack: ["TypeScript", "NextJS", "Node.js", "Postgres", "Docker"],
    githubLink: "https://github.com/PrayashShrestha/newsPortal"
  },
  {
    name: "Real State API",
    description: "Microservice Project with Spring Boot during Web Application Architecture Course",
    details: "Almost all the features available in Zillo which is one of the famous application for realstate in USA. We have implemented comprehensive search and followed Separation of concern principle. All the tracking features are also added.",
    techStack: ["SpringBoot", "MySQL", "Eureka", "Config-Server", "RabbitMQ", "Zipkin"],
    githubLink: "https://github.com/butbuiapp/real-estate-api-microservices"
  },
  // Add more projects as needed
];

const Project: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(null);

  const showModal = (project: any) => {
    setSelectedProject(project);
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="section-projects" id='project'>
      <Title level={2} className="projects-title">My Projects</Title>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <Card
            key={index}
            title={project.name}
            className="project-card"
            extra={<Button type="link" onClick={() => showModal(project)}>Read More</Button>}
          >
            <Text>{project.description}</Text>
          </Card>
        ))}
      </div>
      {selectedProject && (
        <Modal
          title={selectedProject.name}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
        >
          <Text>{selectedProject.details}</Text>
          <div className="tech-stack">
            <Title level={4} className="tech-stack-title">Tech Stack Used</Title>
            <div className="tech-stack-grid">
              {selectedProject.techStack.map((tech: string, index: number) => (
                <div key={index} className="tech-item">{tech}</div>
              ))}
            </div>
          </div>
          <div className="github-link">
            <Link href={selectedProject.githubLink} target="_blank">
              View on GitHub
            </Link>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Project;
