import React from 'react';
import './Layout.css';
import NavigationBar from '../../components/navigationBar/NavigationBar';
import FooterBar from '../../components/footerBar/FooterBar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <NavigationBar />
      <div className="content">
        {children}
      </div>
      <FooterBar/>
    </div>
  );
};

export default Layout;
