import './App.css';
import About from './components/about/About';
import Banner from './components/banner/Banner';
import ContactForm from './components/contact/ContactForm';
import Experience from './components/experience/Experience';
import Projects from './components/projects/Project';
import Layout from './page/layout/Layout';

function App() {
  return (
    <Layout>
      <Banner/>
      <About/>
      <Projects/>
      <Experience/>
      <ContactForm/>
    </Layout>
  );
}

export default App;
