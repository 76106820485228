import React from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import './ContactForm.css';

const { Title } = Typography;
const { TextArea } = Input;

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    console.log('Received values:', values);

    try {
      const response = await fetch('https://s20k51bpz7.execute-api.us-east-2.amazonaws.com/dev/portfolio-lambda', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success('Message sent successfully!');
        form.resetFields();
      } else {
        message.error('Failed to send message.');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while sending the message.');
    }
  };

  return (
    <div className="section-contact" id="contact">
      <Title level={2} className="contact-title">Contact Me</Title>
      <Form
        form={form}
        name="contact"
        onFinish={onFinish}
        layout="vertical"
        className="contact-form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input placeholder="Your Name" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input placeholder="Your Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: 'Please enter a subject!' }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: 'Please enter your message!' }]}
        >
          <TextArea rows={4} placeholder="Your Message" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            Send Message
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
